<template>
  <FullPage :title="$t('passwordResetView.title')">
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <el-form
        ref="form"
        label-position="top"
        :model="formObject"
      >
        <FormItem
          rules="required|min:12"
          :label="$t('passwordResetView.passwordInput')"
          vid="password"
        >
          <el-input
            v-model="formObject.password"
            show-password
            autocomplete="off"
          />
        </FormItem>
        <FormItem
          rules="required|min:12|confirmed:password"
          :label="$t('passwordResetView.confirmPasswordInput')"
        >
          <el-input
            v-model="formObject.confirmPassword"
            show-password
            autocomplete="off"
          />
        </FormItem>
        <FormButtons>
          <el-button
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(resetPassword)"
          >
            {{ $t('passwordResetView.submitButton') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </FullPage>
</template>

<script>
import FormItem from '@/components/FormItem.vue';
import FormButtons from '@/components/FormButtons.vue';
import FullPage from '@/components/FullPage.vue';
import usersApi from '../api/users';

export default {
  name: 'PasswordReset',
  components: {
    FormItem,
    FormButtons,
    FullPage,
  },
  data() {
    return {
      formObject: {
        password: '',
        confirmPassword: '',
      },
    };
  },
  methods: {
    async resetPassword() {
      try {
        await usersApi.resetPassword(
          { password: this.formObject.password, code: this.$route.query.code },
        );
        this.$router.push({ name: 'Login' });
        this.$notify({ showClose: true, type: 'success', message: this.$t('passwordResetView.passwordChangedMessage') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
  },
};
</script>

<style lang="scss">
.password-reset {
  &__content {
    height: 100vh;
  }

  &__title {
    margin-bottom: 40px;
  }
}
</style>
