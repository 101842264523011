<template>
  <el-row
    class="fullpage"
    type="flex"
    justify="center"
    align="middle"
  >
    <el-col
      :xs="22"
      :sm="18"
      :md="12"
      :lg="6"
      class="fullpage__container"
    >
      <slot name="title">
        <h2 class="fullpage__title">
          {{ title }}
        </h2>
      </slot>
      <slot />
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'FullPage',
  props: {
    title: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.fullpage {
  width: 100%;
  height: 100%;

  &__container {
    box-shadow: $--box-shadow-light;
    background-color: $--color-white;
    border-radius: $--border-radius-base;
    padding: 40px;
  }

  &__title {
    margin-bottom: 40px;
  }
}
</style>
